import { lighten, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { createContext, forwardRef, TouchEventHandler, useCallback, useContext } from "react";
import { EDITION_META } from "../../reclaim-api/team/Team.consts";
import { ReclaimEditionStr } from "../../reclaim-api/team/Team.types";
import { EditionBadgeBase, EditionBadgeBaseProps } from "../EditionBadgeBase";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.caption,
    backgroundColor: lighten(theme.colors.grey, 0.6),
    borderRadius: 4,
    color: theme.palette.text.primary,
    display: "inline-block",
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "16px",
    padding: theme.spacing(0, 0.5),
    textTransform: "uppercase",
    pointerEvents: "auto",
  },
}));

export type EditionBadgeProps = Omit<EditionBadgeBaseProps, "paid" | "classes"> & {
  edition: ReclaimEditionStr;
  EditionBadgeBaseClasses?: EditionBadgeBaseProps["classes"];
  shortLabel?: boolean;
};

export type EditionBadgeContextData = { tooltipOpen: boolean; setTooltipOpen: (open: boolean) => void };
export const EditionBadgeContext = createContext<EditionBadgeContextData>({
  tooltipOpen: false,
  setTooltipOpen: () => void 0,
});

export const EditionBadge = forwardRef<HTMLDivElement, EditionBadgeProps>(
  ({ edition, className, EditionBadgeBaseClasses, shortLabel, ...rest }, ref) => {
    const classes = useStyles();
    const { tooltipOpen, setTooltipOpen } = useContext(EditionBadgeContext);

    const handleTouchEndCapture = useCallback<TouchEventHandler<HTMLDivElement>>(
      (e) => {
        if (!tooltipOpen) {
          // preventDefault on touchEnd blocks click event
          e.preventDefault();
          e.stopPropagation();
          setTooltipOpen(true);
        } else setTooltipOpen(false);
      },
      [setTooltipOpen, tooltipOpen]
    );

    return (
      <EditionBadgeBase
        onTouchEndCapture={handleTouchEndCapture}
        className={clsx(classes.root, className)}
        paid={edition !== "LITE" && edition !== "ASSISTANT"}
        classes={EditionBadgeBaseClasses}
        ref={ref}
        {...rest}
      >
        {shortLabel ? EDITION_META[edition].shortLabel || EDITION_META[edition].label : EDITION_META[edition].label}
      </EditionBadgeBase>
    );
  }
);
